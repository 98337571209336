<template>
  <div id="external-request-history" :style="{ width: formWidth }">
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />
    <template v-if="historyProcessId">
      <ProcessHistory
        :process-id="historyProcessId"
        :selected-process="selectedProcess"
        :external-access="true"
        @close="historyProcessId = 0"
      />
    </template>

    <template v-else-if="!loadingBarContent">
      <div class="header col">
        <div class="col text-center">
          <div class="title-1">{{ title }}</div>
          <div class="description">{{ description }}</div>
        </div>
      </div>
      <StateWrapper
        icon="mdi-history"
        title="Process History"
        description="History records not found"
      />
    </template>

    <!-- footer -->

    <div class="footer">
      <div class="label">Powered by</div>
      <img src="@/assets/logo/logo-light.png" alt="logo" />
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import { auth, report } from "@/api/factory.js";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";

export default {
  name: "ExternalRequestHistory",

  components: {
    StateWrapper,
    ProcessHistory,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    workflowId: {
      type: String,
      required: true,
    },

    processId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingBarContent: true,
      formWidth: "100%",
      title: "",
      description: "",
      selectedProcess: {},
      historyProcessId: 0,
    };
  },

  watch: {
    userId: {
      immediate: true,
      handler: "getSession",
    },
  },

  methods: {
    async getSession() {
      const { error } = await auth.externalLogin(this.tenantId, this.userId);
      if (error) {
        this.loadingBarContent = false;
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getProcess();
    },

    async getProcess() {
      // {
      //           criteria: "requestNo",
      //           condition: "IS_EQUALS_TO",
      //           value: "REQ-15",
      //         },
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.processId,
              },
            ],
          },
        ],
        itemsPerPage: 10,
        currentPage: 1,
        mode: "",
      });
      if (error) {
        this.loadingBarContent = false;
        this.$alert.error(error);
        return;
      }
      this.loadingBarContent = false;
      if (payload.meta.totalItems) {
        if (payload.data.length) {
          if (payload.data[0].value.length) {
            this.selectedProcess = payload.data[0].value[0];
            this.historyProcessId = this.processId;
            this.title = this.selectedProcess.requestNo;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#external-request-history {
  margin: auto;
  padding: 16px;

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}
</style>
